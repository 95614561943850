export interface Recommendation {
  id: string;
  name: string;
  fullname: string;
  packsize: number;
  estrogen: string;
  estrogenDose: number;
  progestin: string;
  progestinDose: number;
  generationOfProgestin: number;
  regimen: Regimen;
  contentId: string;
  cedarwoodId: string;
}

export interface Regimen {
  id: number;
  shortTitle: string;
  daysOn: number;
  daysOff: number;
}

export interface PillFromPrismic {
  id: string;
  uid: null;
  type: string;
  href: string;
  tags: string[];
  first_publication_date: string;
  last_publication_date: string;
  slugs: string[];
  linked_documents: any[];
  lang: string;
  alternate_languages: any[];
  data: {
    name: any[];
    full_description: any[];
    positives: any[];
    negatives: any[];
  };
}

export interface RecommendationsFromOMS {
  primary: Recommendation;
  secondary: Recommendation;
  tertiary: Recommendation;
}

export enum CountryCode {
  NL = 'NL',
  GB = 'GB',
  IE = 'IE',
  Other = 'Other',
}
