export default function getOmsUrl(location) {
  const local = 'http://localhost:3001';
  const staging = 'https://api.orders.staging.yourdaye.com';
  const prod = 'https://api.orders.yourdaye.com';

  const omsUrl = {
    localhost: local,
    'contraception.development.yourdaye.com': staging,
    'contrueception.yourdaye.com': prod,
    'contraception.yourdaye.com': prod,
  }[location];

  if (!omsUrl) {
    throw Error(
      `OMS url not configured for ${location}. See getOmsUrl in project root`
    );
  }

  return omsUrl;
}
