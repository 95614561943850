import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { Account } from '@dayetopia/library';

import getOmsUrl from '../../getOmsUrl';
import { Context } from '../components/Context';
import keys from '../../keys.json';
import useLogin from '../components/useLogin';
import { CountryCode } from '../types';

export default function AccountPage() {
  const { state, dispatch } = useContext(Context);
  const { firebaseAuth, loggedIn } = state;
  const omsUrl = window ? getOmsUrl(window.location.hostname) : '';
  const runLogin = useLogin();

  function showStatusMessage(message: string) {
    dispatch({ type: 'setStatusMessage', data: message });
  }

  return (
    <Account
      platform="CRE"
      currency={{ letterCode: 'GBP', symbol: '£' }}
      omsUrl={omsUrl}
      firebaseAuth={firebaseAuth}
      klavioKey={keys.klavpub}
      loggedIn={loggedIn}
      runLogin={runLogin}
      navigate={navigate}
      showStatusMessage={showStatusMessage}
      selectedBox={undefined}
      country={CountryCode.GB}
    />
  );
}
